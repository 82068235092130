<!-- 日期库存 -->
<template>
  <div id="orderList">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div class="top">
        <a-form layout="inline">
          <a-form-item>
            <a-button
              icon="plus"
              type="primary"
              @click="handleDetail()"
            >
              新建日期库存
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="stockListColumns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        row-key="stockId"
      >
        <span slot="operation" slot-scope="text, record">
          <a-button type="link" @click="handleDetail(record)">编辑</a-button>
          <!-- <a-popconfirm
            style="margin-left: 10px"
            :title="`是否确定删除`"
            @confirm="handleDelete(record)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm> -->
          
        </span>
        <span slot="userList" slot-scope="text, record">
          <div v-for="item in record.userList">{{ item.name }}: {{ item.cardId }}</div>
        </span>
      </a-table>
      <MyPagination
        :count="count"
        :pageNo="params.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </a-spin>
    <calendar-drawer :visible="showCalendarDrawer" :dates="selectValidRange"  :info="currentRecord" @reload="handleReload" @close="onClose" />
  </div>
</template>

<script>
import { getStockList, deleteStock, getCalendarList } from '@/request/api/tiktok'
import moment from 'moment'
import { stockListColumns } from '../constants'
import MyPagination from '@/components/pagination/MyPagination'
import { codeFn } from '@/utils/tools'
import EditDrawer from './editDrawer.vue'
import CalendarDrawer from './calendarDrawer.vue'
import { message } from 'ant-design-vue'
export default {
  components: {
    MyPagination,
    EditDrawer,
    CalendarDrawer,
  },
  created() {
    this.fetchData()
  },
  data() {
    return {
      showCalendarDrawer: false,
      editDrawer: false,
      currentRecord: {},
      spinning: false, // 全局loading
      tip: '',
      params: {
        pageNo: 1,
        pageSize: 20,
      },
      count: 0,
      tableLoading: true,
      stockListColumns,
      tableData: [
        {
          id: '1',
          name: '日历票名称',
          businessName: '关联商品',
          goTime: '出行前核销天数',
          status: '未出行'
        }
      ],
      selectValidRange: []
    }
  },
  methods: {
    moment,
    onClose(flag = false) {
      if (flag) this.fetchData()
      this.showCalendarDrawer = false
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.params.pageNo = current
      this.params.pageSize = pageSize
      this.fetchData()
    },
    // 查看详情
    handleDetail(record = {}) {
      this.currentRecord = record
      if (record.stockId) {
        this.fetchCalendarList()
        return
      }
      this.showCalendarDrawer = true
      this.selectValidRange = []

    },
    handleReload(info) {
      this.currentRecord.stockId = info.stockId
      this.currentRecord.searchDate = info.date
      this.fetchCalendarList()
    },
    // 获取可点击的日期
    async fetchCalendarList() {
      const params = {
        stockId: this.currentRecord.stockId,
        date: this.currentRecord.searchDate
      }
      const { code, data } = await getCalendarList(params)
      if (code !== 200) return
      this.selectValidRange = data
      this.showCalendarDrawer = true
      // data.forEach(element => {
      //   this.validRange.push(moment(element.date))
      // });
    },
    
    async fetchData() {
      this.tableLoading = true
      const params = this.params
      const { code, data } = await getStockList(params)
      this.tableLoading = false
      if (code !== 200) return
      this.count = data.count;
      this.tableData = data.lists
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#orderList");
    },
    async handleDelete(record) {
      const { code } = await deleteStock({ stockId: record.stockId })
      if (code !== 200) return
      message.success('删除成功')
      this.fetchData()
    }
  },
};
</script>
 
<style lang="less" scoped>
.top {
  .top_line {
    margin-bottom: 20px;
    display: flex;
    span {
      margin-right: 20px;
      /deep/.ant-input {
        width: 220px;
      }
      /deep/.ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }
      /deep/.ant-calendar-picker-input {
        margin-left: 10px;
        width: auto;
      }
      /deep/.ant-calendar-picker {
        width: 400px !important;
      }
    }
    .ant-btn {
      margin-right: 20px;
    }
  }
}
</style>