<template>
  <div class="padding24 container" >
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="日历票设置">
        <ticket-setting />
      </a-tab-pane>
      <a-tab-pane key="2" tab="日期库存设置" force-render>
        <stock-setting />
      </a-tab-pane>
      
    </a-tabs>
  </div>
</template>

<script>
import StockSetting from './stockSetting.vue';
import ticketSetting from './ticketSetting.vue';
export default {
	components: { ticketSetting, StockSetting },
  data() {
    return {};
  },
  methods: {
    callback(key) {
      // console.log(key);
    },
  },
};
</script>
 
<style lang="less" scoped>
.top {
  .top_line {
    margin-bottom: 20px;
    display: flex;
    span {
      margin-right: 20px;
      /deep/.ant-input {
        width: 220px;
      }
      /deep/.ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }
      /deep/.ant-calendar-picker-input {
        margin-left: 10px;
        width: auto;
      }
      /deep/.ant-calendar-picker {
        width: 400px !important;
      }
    }
    .ant-btn {
      margin-right: 20px;
    }
  }
}
</style>