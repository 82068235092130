<template>
  <div>
    <a-drawer
      title="库存设置"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      width="60%"
      destroy-on-close
      @close="onClose"
    >
      <div class="container-box">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="title" label="日期库存名称" prop="title">
            <a-input v-model="form.title" placeholder="请输入日期库存名称" @blur="onTitleChange"/>
          </a-form-model-item>
          <a-form-model-item ref="date" label="日期" prop="date">
            <div :style="{ border: '1px solid #d9d9d9', borderRadius: '4px' }">
              <!-- valueFormat="yyyy-MM-DD" :value="form.date"   -->
              <a-calendar valueFormat="yyyy-MM-DD" @change="onChangeCalendar" >
                <div slot="dateFullCellRender" slot-scope="value" :class="`events ant-fullcalendar-date ${ getListData(value).dateId? ' active': ''}`" @click="handlerCalender(getListData(value))">
                  <div>{{ getListData(value).value }}</div>
                  <div v-show="getListData(value).dateId">{{ getListData(value).status === 0 ? '未上架' : '已上架'}}</div>
                </div>
              </a-calendar>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div style="text-align: center;">
          <a-button type="primary" @click="onSubmit">
              保存
            </a-button>
            <a-button style="margin-left: 10px;" @click="onClose">
              取消
            </a-button>
        </div>
      </div>
      <inventory-modal :visible="inventoryModalVisible" :stock-id="form.stockId" :info="curRecord" @close="onSettingClose" />
    </a-drawer>
  </div>
</template>
<script>
import moment from "moment"
import { addStock, editStock } from "@/request/api/tiktok";
import { message, Modal } from 'ant-design-vue';
import inventoryModal from './InventoryModal.vue';

export default {
	components: { inventoryModal },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => ({})
    },
    dates: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      isAdd: true,
      form: {
        title: '',
        data: ''
      },
      defaultValue: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      rules: {
        title: [{ required: true, message: '请输入日期库存名称', trigger: 'blur' }],
        data: [{ required: true, message: '请选择日期', trigger: 'change' }]
      },
      inventoryModalVisible: false,
      curRecord: {},
      reloadParams: {}
    }
  },
  methods: {
    getListData(value) {
      const curDate = moment(value).format('yyyy-MM-DD')
      let obj = {
        date: curDate
      }
      if (this.dates.length === 0) {
        obj.value = value.date()
        return obj
      }
      this.dates.forEach(item => {
        if (item.date === curDate) {
          item.value = value.date()
          obj = item
        } else {
          obj.value = value.date()
        }
      })
      return obj
    },
    async onTitleChange() {
      if (!this.isAdd || !this.form.title) return
      const { code, data } = await addStock({ title: this.form.title })
      if (code !== 200) return
      this.form.stockId = data.stockId
      this.isAdd = false
    },
    afterVisibleChange(val) {
      if (!val) return
      this.isAdd = !this.info.stockId
      this.form = {
        ...this.info,
        title: this.info.stockTitle
      }
    },
    onClose() {
      this.$emit('close')
    },
    onSettingClose(flag = false) {
      this.reloadParams.stockId =  this.form.stockId
      if (flag) this.$emit('reload', this.reloadParams)
      this.inventoryModalVisible = false
    },
    onChangeCalendar(value, a) { 
      if (!this.form.stockId) {
        Modal.warning({
          title: '请先添加日期库存名称',
        });
        return
      }
      this.form.date = moment(value).format('yyyy-MM-DD')
      this.reloadParams.date = moment(value).format('yyyy-MM')
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          // this.isAdd ? await editStock({stockId: this.form.stockId, title: this.form.title}) : await addStock({ title: this.form.title }) 
          await editStock({stockId: this.form.stockId, title: this.form.title})
          message.success('操作成功')
          this.$emit('close', true)
        } else {
          return false;
        }
      });
    },
    handlerCalender(item) {
      if (!this.form.stockId) return
      this.curRecord = item
      this.inventoryModalVisible = true
    }
  }
};
</script>
<style lang="less" scoped>
.active {
  background: #e6f7ff !important;
  width: 100%;
  height: 100%;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mt10 {
  margin-top: 10px;
}
/deep/ .ant-fullcalendar-header .ant-radio-group{
  display: none;
}
// /deep/ .ant-input {
//   width: 80%;
//   margin-right: 5px;
// }
/deep/ form .ant-select {
  width: unset ;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  border-top-color: #e8e8e8;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background-color: #ffffff;
  
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
text-align: right;
}
</style>