import { render, staticRenderFns } from "./ticketSetting.vue?vue&type=template&id=7557469c&scoped=true&"
import script from "./ticketSetting.vue?vue&type=script&lang=js&"
export * from "./ticketSetting.vue?vue&type=script&lang=js&"
import style0 from "./ticketSetting.vue?vue&type=style&index=0&id=7557469c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7557469c",
  null
  
)

export default component.exports