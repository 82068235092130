<!-- 日历票 -->
<template>
  <div id="orderList">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div class="top">
        <a-form layout="inline">
          <a-form-item>
            <a-button
              icon="plus"
              type="primary"
              @click="handleDetail()"
            >
              新建日历票
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="ticketListColumns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        :row-key="record => record.ticketId"
      >
        <span slot="operation" slot-scope="text, record">
          <a-button type="link" @click="handleDetail(record)">编辑</a-button>
          <a-popconfirm
            style="margin-left: 10px"
            :title="`是否确定${record.status === 0 ? '上架' : '上架'}当前日历票`"
            @confirm="handleChangeStatus(record)"
          >
            <a>{{ record.status === 0 ? '上架' : '下架' }}</a>
          </a-popconfirm>
          <!-- <a-button type="link" @click="handleDelete(record)">删除</a-button> -->
        </span>
      </a-table>
      <MyPagination
        :count="count"
        :pageNo="params.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </a-spin>
    <EditDrawer :visible="editDrawer" :info="currentRecord" @close="closeDrawer" />
  </div>
</template>

<script>
import moment from 'moment'
import { ticketListColumns } from '../constants'
import { getTicketList, changeTicktStatus } from '@/request/api/tiktok'
import MyPagination from '@/components/pagination/MyPagination'
import { codeFn } from '@/utils/tools'
import EditDrawer from './editDrawer.vue'
import { message } from 'ant-design-vue'
export default {
  components: {
    MyPagination,
    EditDrawer,
  },
  created() {
    this.fetchData()
    this.isShowOrderExport = codeFn('listenerOrderExport')
  },
  data() {
    return {
      editDrawer: false,
      currentRecord: {},
      spinning: false, // 全局loading
      tip: '',
      params: {
        keys: '',
        pageNo: 1,
        pageSize: 20,
      },
      count: 0,
      tableLoading: true,
      ticketListColumns,
      tableData: [],
      isShowOrderExport: false, // 同步订单是否隐藏
      form: {},
    }
  },
  methods: {
    moment,
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.params.pageNo = current
      this.params.pageSize = pageSize
      this.fetchData()
    },
    // 查看详情
    handleDetail(record = {}) {
      this.editDrawer = true
      this.currentRecord = record
    },
    // 获取第三方订单列表
    async fetchData() {
      this.tableLoading = true
      const params = this.params
      const { code, data } = await getTicketList(params)
      this.tableLoading = false
      if (code !== 200) return
      this.tableData = data.lists
      this.count = data.count;
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#orderList");
    },
    // handleDelete(record) {
    //   console.log('删除')
    // },
    async handleChangeStatus(record) {
      record.status = record.status === 0 ? 1 : 0
      const params = {
        ticketId: record.ticketId,
        status: record.status
      }
      const { code } = await changeTicktStatus(params)
      if (code !== 200) return
      message.success('操作成功')
    },
    closeDrawer(val) {
      this.editDrawer = false
      if (val) this.fetchData()
    }
  },
};
</script>
 
<style lang="less" scoped>
.top {
  .top_line {
    margin-bottom: 20px;
    display: flex;
    span {
      margin-right: 20px;
      /deep/.ant-input {
        width: 220px;
      }
      /deep/.ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }
      /deep/.ant-calendar-picker-input {
        margin-left: 10px;
        width: auto;
      }
      /deep/.ant-calendar-picker {
        width: 400px !important;
      }
    }
    .ant-btn {
      margin-right: 20px;
    }
  }
}
</style>