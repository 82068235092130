<template>
  <div>
    <a-modal
      :title="`${form.date}库存设置`"
      :visible="visible"
      width="50%"
      @ok="onSubmit"
      @cancel="onClose"
    >
    <div class="container-box">
       <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="status" label="状态" prop="status" name="status">
          <a-radio-group class="mt10" v-model="form.status" >
            <a-radio class="radio-item" :value="1">
              <span>已上架</span>
            </a-radio>
            <a-radio class="radio-item"  :value="0">
              <span style="margin-right: 30px;">未上架</span>
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
      
   
  
        <a-button class="editable-add-btn mt10" type="primary" @click="handleAdd">
          新建
        </a-button>
        <a-table bordered :data-source="dataSource" :columns="columns">
          <template slot="batchTitle" slot-scope="text, record, index">
            <a-input
              v-if="editableData[record.key]"
              v-model:value="editableData[record.key].batchTitle"
              style="margin: -5px 0"
            />
            <template v-else>
              {{ text }}
            </template>
          </template>
          <template slot="validNum" slot-scope="text, record, index">
            <a-input
              v-if="editableData[record.key]"
              v-model:value="editableData[record.key].validNum"
              style="margin: -5px 0"
            />
            <template v-else>
              {{ text }}
            </template>
          </template>
          <template slot="sort" slot-scope="text, record">
            <a-input
              v-if="editableData[record.key]"
              v-model:value="editableData[record.key].sort"
              style="margin: -5px 0"
            />
            <template v-else>
              {{ text }}
            </template>
          </template>
          <template slot="operation" slot-scope="text, record, index">
            <span v-if="editableData[record.key]">
              <a @click="() => save(record.key)" style="margin-right: 5px;">保存</a>
              <a-popconfirm title="确定取消？" @confirm="() => cancel(record.key)">
                <a>取消</a>
              </a-popconfirm>
            </span>
            
            <a v-else href="javascript:;" @click="() => edit(record.key)">编辑</a>
          </template>
        </a-table>
      </div>
    
    </a-modal>
  </div>
</template>
<script>
import { getBatchList, editBatch, addBatch, addCalendar,editCalendar } from "@/request/api/tiktok";
import { cloneDeep } from 'lodash'
import { message } from 'ant-design-vue';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => ({})
    },
    stockId: {
      type: Number
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.form = this.$options.data().form
        this.editableData = this.$options.data().editableData
        this.dataSource = []
        return
      }
      this.form = Object.assign(this.form, this.info)
      this.isAdd = !this.info.dateId
      if (this.info.dateId) this.fetchData()
    }
  },
  data() {
    return {
      form: {
        status: 0
      },
      labelCol: { span: 2 },
      wrapperCol: { span: 14 },
      rules: {
        status: [ { required: true, message: '请选择状态', trigger: 'change' }],
      },
      dataSource: [],
      columns: [
        {
          title: '排序',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
        },
        {
          title: '场次名称',
          dataIndex: 'batchTitle',
          width: '35%',
          scopedSlots: { customRender: 'batchTitle' },
        },
        {
          title: '库存',
          dataIndex: 'validNum',
          scopedSlots: { customRender: 'validNum' },
        },
        {
          title: '已登记用户数量',
          dataIndex: 'usedNum',
          width: 150,
          scopedSlots: { customRender: 'usedNum' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          scopedSlots: { customRender: 'operation' },
        },
      ],
      editableData: {},
      isAdd: true
    };
  },
  methods: {
    async fetchData() {
      const { code, data } = await getBatchList({ dateId: this.info.dateId })
      if (code !== 200) return
      data.forEach((item, index) => {
        item.key = index
      });
      this.dataSource = data
    },
    onClose() {
      this.$emit('close')
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.isAdd ? await this.onAddCalendar() : await this.onEditCalendar()
          message.success('操作成功')
          this.$emit('close', true)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 添加日历库存日期
    async onAddCalendar() {
      const { data: { dateId }} =  await addCalendar({
        stockId: this.stockId,
        date: this.form.date,
        status: this.form.status
      })
      this.form.dateId = dateId
    },
    async onEditCalendar() {
      const { code } =  await editCalendar({
        dateId: this.form.dateId,
        status: this.form.status
      })
      if (code !== 200) return
      
    },
    handleAdd() {
      const { dataSource } = this;
      const newData = {
        key: dataSource.length + 1,
        sort: '',
        batchTitle: '',
        validNum: '',
      };
      this.dataSource = [...dataSource, newData];
    },
    validateInfo(item) {
      if (!item.batchTitle) {
        message.warn('请输入场次名称')
        return false
      }
      if (!item.validNum) {
        message.warn('请输入库存')
        return false
      }
      return true
    },
    edit(key) {
      const item = cloneDeep(this.dataSource.filter(item => key === item.key)[0])
      this.$set(this.editableData, key, item )
    },
    async save(key) {
      const item = this.editableData[key]
      if (!this.validateInfo(item)) return

      if (!this.form.dateId) {
        this.isAdd = false
        await this.onAddCalendar()
      }
      if (item.batchId) {
        await editBatch({...item, validNum: +item.validNum, sort: +item.sort})
      } else {
        const { data: { batchId } } = await addBatch({ ...item, dateId: this.form.dateId, validNum: +item.validNum, sort: +item.sort })
        this.editableData[key].batchId = batchId
      }
      Object.assign(this.dataSource.filter(item => key === item.key)[0], this.editableData[key])
      this.$delete(this.editableData, key)
    },
    cancel(key) {
      this.$delete(this.editableData, key)
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mt10 {
  margin-top: 10px;
}
/deep/ .ant-fullcalendar-header .ant-radio-group{
  display: none;
}
// /deep/ .ant-input {
//   width: 80%;
//   margin-right: 5px;
// }
/deep/ form .ant-select {
  width: unset ;
}
</style>