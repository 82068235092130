<template>
  <a-modal
    title="库存列表"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="tableLoading"
      
      :row-selection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :row-key="record => record.stockId"
    >
        
    </a-table>
    <MyPagination
      :count="count"
      :pageNo="params.pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
  </a-modal>
</template>

<script>
import { getStockList } from '@/request/api/tiktok'
import MyPagination from '@/components/pagination/MyPagination'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selectId: {
      type: Number,
      default: 0
    }
  },
  components: { MyPagination },
  watch: {
    visible(val) {
      if (val) this.selectedRowKeys = [this.selectId]
    }
  },
  data() {
    return {
      tableData: [],
      count: 0,
      columns: [{
        title: '日期库存名称',
        dataIndex: 'stockTitle',
        key: 'stockTitle',
      },],
      params: {
        pageNo: 1,
        pageSize: 20
      },
      tableLoading: false,
      selectedRowKeys: [],
      selectedRows: []
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.tableLoading = true
      const { code, data } = await getStockList(this.params)
      this.tableLoading = false
      if (code !== 200) return
      this.tableData = data.lists
      this.count = data.count
    },
    handleOk(e) {
      if (!this.selectedRows.length) return
      console.log(this.selectedRows)
      this.$emit('ok', this.selectedRows[0])
    },
    handleCancel(e) {
      this.$emit('close')
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.params.pageNo = current
      this.params.pageSize = pageSize
      this.fetchData()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-modal-body {
  padding-top: 0;
}
</style>