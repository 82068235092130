<template>
  <div>
    <a-drawer
      title="添加/编辑日历票"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      width="60%"
      @close="onClose"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="title" label="日历票名称" prop="title">
          <a-input v-model="form.title" placeholder="请输入日历票名称" />
        </a-form-model-item>
        <a-form-model-item label="关联方式" prop="isSku">
          <a-radio-group v-model="form.isSku">
            <a-radio :value="1">
              sku id绑定
            </a-radio>
            <a-radio :value="2">
              商品id绑定
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item ref="productId" label="关联商品id/sku id" prop="productId">
          <a-input v-model="form.productId" placeholder="关联商品id/sku id" />
        </a-form-model-item>
        <a-form-model-item ref="nums" label="出行人数" prop="nums">
          <a-input-number v-model="form.nums" placeholder="出行人数" style="width: 120px;" />
        </a-form-model-item>
        <a-form-model-item ref="advanceDays" label="出行前核销天数（0-30）" prop="advanceDays">
          <a-input-number :min="0" :max="30" v-model="form.advanceDays" style="width: 120px;"  />
        </a-form-model-item>
        <a-form-model-item ref="checkinTips" label="小程序-登记页面提示文案" prop="checkinTips">
          <a-input v-model="form.checkinTips" placeholder="请输入小程序-登记页面提示文案"  />
        </a-form-model-item>
        <a-form-model-item label="小程序-登记需知" prop="tips2">
          <div class="flex mt10" v-for="(item, index) in form.content" :key="index">
            <div style="width: 100%; margin-right: 10px;">
              <a-input v-model="item.tipstTitle" placeholder="请输入小程序-登记需知标题" />
              <a-textarea v-model="item.tipsContent" :rows="3" placeholder="请输入小程序-登记需知内容" />
            </div>
            <div>
              <a-icon v-if="index > 0" size="50" type="minus-circle" @click="reduceFormItem(index)" />
            </div>
          </div>
          <a-button type="primary" @click="addFromItem">新增</a-button>
        </a-form-model-item>
        <a-form-model-item label="登记成功页面提示标题" prop="checkinSuccessTitle">
          <a-input v-model="form.checkinSuccessTitle" placeholder="登记成功页面提示标题" />
        </a-form-model-item>
        <a-form-model-item label="登记成功页面内容" prop="checkinSuccessContent">
          <a-textarea v-model="form.checkinSuccessContent" :rows="3" placeholder="登记成功页面内容" />
        </a-form-model-item>
        <a-form-model-item label="关联库存" prop="stockName">
          <span style="margin-right: 10px;">{{ form.stockName }}</span>
          <a-input type="hidden" v-model="form.stockName" />
          <a-button v-if="!isEdit"  type="primary" @click="showSockDialog = true">{{ form.stockName ? '更换库存' : '关联库存'}}</a-button>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
          <a-button style="margin-left: 10px;" @click="onClose">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
    <stock-dialog :visible="showSockDialog" :select-id="form.stockId" @close="showSockDialog = false" @ok="onSelectStock"/>
  </div>
</template>
<script>
import stockDialog from './stockDialog.vue';
import { getTicketDetail, editTicket, addTicket } from '@/request/api/tiktok'
import { message } from 'ant-design-vue';
export default {
  components: {stockDialog},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isEdit: false,
      showSockDialog: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      other: '',
      form: {
        content: [{
          tipstTitle:'',
          tipsContent:''
        }]
      },
      rules: {
        title: [ { required: true, message: '请输入日历票名称', trigger: 'blur' }],
        isSku: [ { required: true, message: '请输入关联商品id/sku id', trigger: 'blur' }],
        productId: [ { required: true, message: '请输入第三方商品号', trigger: 'blur' }],
        nums: [
          { required: true, message: '请输入输入出行人数', trigger: 'change' },
        ],
        advanceDays: [
          { required: true, message: '请输入出行前核销天数', trigger: 'change' },
        ],
        checkinTips: [ { required: true, message: '请输入小程序登记页面提示文案', trigger: 'blur' }],
        checkinSuccessTitle: [ { required: true, message: '请输入登记成功页面提示标题', trigger: 'blur' }],
        checkinSuccessContent: [{ required: true, message: '请输入登记成功页面内容', trigger: 'blur' }],
        stockName: [{ required: true, message: '请选择关联库存', trigger: 'change' }],
        tips2: [
          { required: true, validator: this.validateContent, trigger: "change" },
        ]
      },
    };
  },
  
  methods: {
    validateContent(rule, value, callback) {
      const content = this.form.content
      for (let i = 0; i < content.length; i++) {
        const element = content[i];
        if (!element.tipstTitle) {
          callback("请输入登记须知标题");
          return
        } else if (!element.tipsContent) {
          callback("请输入登记须知内容");
          return
        }
      }
      callback();
    },
    afterVisibleChange(val) {
      if (!val) {
        this.form = this.$options.data().form
        return
      }
      if (this.info.ticketId) {
        this.isEdit = true
        this.getDetail()
      }
    },
    async getDetail() {
      const params = {
        ticketId: this.info.ticketId
      }
      const { code, data } = await getTicketDetail(params)
      if (code !== 200) return
      this.form = data
    },
    onClose(flag = false) {
      this.$emit('close', flag)
    },
    addFromItem() {
      this.form.content.push({
        title:'',
        content: ''
      })
    },
    reduceFormItem(index) {
      this.form.content.splice(index, 1)
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isEdit ? this.editInfo() : this.addInfo()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async editInfo() { 
      const params = {
        ...this.form,
      }
      const { code } = await editTicket(params)
      if (code !== 200) return 
      message.success('修改成功')
      this.onClose(true)
    },
    async addInfo() {
      const params = {
        ...this.form,
      }
      const { code } = await addTicket(params)
      if (code !== 200) return
      message.success('添加成功')
      this.onClose(true)
    },
    // 选择库存回调
    onSelectStock(val) {
      this.form.stockName = val.stockTitle
      this.form.stockId = val.stockId
      this.showSockDialog = false
    }
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mt10 {
  margin-top: 10px;
}
</style>